import React, { useState, useEffect, useRef } from "react";
import { graphql, navigate } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import ReactPlayer from "react-player";
import styled from "styled-components";
import {
  mainRed,
  navyBlue,
  aku,
  innerWidth,
  gotham,
  mainWhite,
  gothamBold,
  transHover,
  screen,
} from "../components/common/variables";
import { GatsbyImage } from "gatsby-plugin-image";
import { moveBgColor } from "../components/common/animations";
import VideoModal from "../components/video-modal";
import { Link as Spy } from "react-scroll";
import Arrow from "../images/svg/right-arrow.svg";
import { toSlug, getVimeoId } from "../components/common/functions";
import logo from "../images/tenalphas-moving-content.jpg";

const Wrapper = styled.div`
  padding-bottom: 90px;
  padding-top: 124px;
  @media ${screen.small} {
    padding-top: 135px;
  }
  @media ${screen.medium} {
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
  }

  .heading {
    color: ${navyBlue};
    font-family: ${aku};
    font-size: 2.1rem;
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
      font-size: 2.7rem;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle;
      @media ${screen.small} {
        width: 19px;
        margin-right: 14px;
      }
      @media ${screen.medium} {
        width: 24px;
      }

      .body {
        fill: ${mainRed};
        transition: ${transHover};
      }
    }
  }

  .primary-content {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }

    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    &__row {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      flex-direction: column;
      @media ${screen.small} {
        flex-direction: row;
        margin-top: 90px;
      }

      &:first-child {
        margin-top: 15px;
        @media ${screen.small} {
          margin-top: 30px;
        }
      }

      .texts-column {
        color: ${navyBlue};
        max-width: 1000px;
        width: 100%;

        p {
          font-family: ${gotham};
          font-size: 1rem;
          margin-bottom: 16px;
          @media ${screen.small} {
            font-size: 1.1rem;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          font-family: ${gothamBold};
          color: ${navyBlue};
          font-size: 1rem;
          @media ${screen.small} {
            font-size: 1.1rem;
          }
          &:hover {
            text-decoration: underline;
          }
        }

        strong {
          font-family: ${gothamBold};
        }

        h1 {
          font-size: 2rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 2.2rem;
          }
        }
        h2 {
          font-size: 1.8rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 2rem;
          }
        }
        h3 {
          font-size: 1.6rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.8rem;
          }
        }
        h4 {
          font-size: 1.4rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.6rem;
          }
        }
        h5 {
          font-size: 1.3rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 8px 0;
            font-size: 1.4rem;
          }
        }
        h6 {
          font-size: 1.1rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.2rem;
          }
        }

        ul {
          margin: 15px 0 30px 0;
          padding-left: 15px;
          @media ${screen.small} {
            padding-left: 25px;
          }

          li {
            color: ${navyBlue};
            line-height: 1.5;
            list-style: inherit;
            font-family: ${gotham};
            margin-bottom: 16px;
            list-style: none;
            &:before {
              content: ">";
              margin-right: 10px;
            }
          }
        }

        ol {
          margin: 15px 0 30px 0;
          padding-left: 35px;
          @media ${screen.small} {
            padding-left: 45px;
          }

          li {
            color: ${navyBlue};
            line-height: 1.5;
            list-style: inherit;
            font-family: ${gotham};
            margin-bottom: 16px;
          }
        }
      }

      .img-column {
        width: 100%;
        order: -1;
        @media ${screen.small} {
          order: 1;
          padding-left: 35px;
          max-width: 330px;
        }
        @media ${screen.medium} {
          padding-left: 45px;
          max-width: 450px;
        }
        @media ${screen.large} {
          max-width: 620px;
          padding-left: 70px;
        }

        &__img {
          @media ${screen.small} {
            margin-bottom: 100px;
          }
          &:first-child {
            margin-bottom: 35px;
            @media ${screen.small} {
              margin-bottom: 100px;
            }
          }
          &:last-child {
            margin-bottom: 15px;
            @media ${screen.small} {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .secondary-content {
    padding: 45px 0;
    margin-top: 90px;
    background-image: ${(props) =>
      `linear-gradient(90deg, ${props.bgColour[0].colour}, ${props.bgColour[1].colour}, ${props.bgColour[2].colour}, ${props.bgColour[3].colour})`};
    background-size: 360%;
    animation: ${moveBgColor} 8s infinite alternate;
    @media ${screen.small} {
      padding: 90px 0;
    }

    &__heading {
      color: ${mainWhite};
      font-family: ${aku};
      font-size: 2.1rem;
      vertical-align: middle;
      margin-bottom: 45px;
      max-width: ${innerWidth};
      margin: 0 auto;
      padding-left: 25px;
      padding-right: 25px;
      @media ${screen.small} {
        padding-left: 40px;
        padding-right: 40px;
        font-size: 2.7rem;
      }
      @media ${screen.medium} {
        padding-left: 50px;
        padding-right: 50px;
      }

      @media ${screen.xLarge} {
        padding-left: 0;
        padding-right: 0;
      }

      a {
        pointer-events: none;
      }

      svg {
        width: 14px;
        margin-right: 8px;
        vertical-align: middle;
        @media ${screen.small} {
          width: 19px;
          margin-right: 14px;
        }
        @media ${screen.medium} {
          width: 24px;
        }

        .body {
          fill: ${mainWhite};
          transition: ${transHover};
        }
      }

      .active {
        .body {
          fill: ${mainRed};
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      max-width: ${innerWidth};
      margin: 20px auto 0 auto;
      padding-left: 25px;
      padding-right: 25px;
      @media ${screen.small} {
        padding-left: 40px;
        padding-right: 40px;
        margin: 60px auto 0 auto;
      }

      @media ${screen.medium} {
        padding-left: 50px;
        padding-right: 50px;
        flex-direction: row;
      }

      @media ${screen.xLarge} {
        padding-left: 0;
        padding-right: 0;
      }

      &__description {
        color: ${mainWhite};
        max-width: 1000px;
        width: 100%;

        p {
          font-family: ${gotham};
          font-size: 1rem;
          margin-bottom: 16px;
          @media ${screen.small} {
            font-size: 1.1rem;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          font-family: ${gothamBold};
          color: ${mainWhite};
          font-size: 1rem;
          @media ${screen.small} {
            font-size: 1.1rem;
          }
          &:hover {
            text-decoration: underline;
          }
        }

        strong {
          font-family: ${gothamBold};
        }

        h1 {
          font-size: 2rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 2.2rem;
          }
        }
        h2 {
          font-size: 1.8rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 2rem;
          }
        }
        h3 {
          font-size: 1.6rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.8rem;
          }
        }
        h4 {
          font-size: 1.4rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.6rem;
          }
        }
        h5 {
          font-size: 1.3rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 8px 0;
            font-size: 1.4rem;
          }
        }
        h6 {
          font-size: 1.1rem;
          font-family: ${gotham};
          margin: 8px 0;
          @media ${screen.small} {
            margin: 10px 0;
            font-size: 1.2rem;
          }
        }

        ul,
        ol {
          margin: 15px 0 30px 0;
          padding-left: 35px;
          @media ${screen.small} {
            padding-left: 45px;
          }

          li {
            color: ${navyBlue};
            line-height: 1.5;
            list-style: inherit;
            font-family: ${gotham};
            margin-bottom: 16px;
          }
        }
      }

      &__img {
        width: 100%;
        margin-bottom: 20px;
        @media ${screen.small} {
          margin-bottom: 30px;
          max-width: 330px;
          padding-right: 35px;
          max-width: 508px;
        }
        @media ${screen.medium} {
          padding-right: 45px;
          max-width: 450px;
          margin-bottom: 0;
        }
        @media ${screen.large} {
          max-width: 620px;
          padding-right: 70px;
        }
      }
    }
  }

  .featured-project {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-top: 45px;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 90px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }

    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    &__heading {
      color: ${navyBlue};
      font-family: ${gotham};
      font-size: 1.3rem;
      @media ${screen.small} {
        font-size: 2rem;
      }

      svg {
        width: 14px;
        margin-right: 8px;
        @media ${screen.small} {
          width: 19px;
          margin-right: 14px;
        }
        @media ${screen.medium} {
          width: 24px;
        }

        .body {
          fill: ${navyBlue};
          transition: ${transHover};
        }
      }

      .active {
        .body {
          fill: ${mainRed};
        }
      }
    }

    &__video-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 10px;
      @media ${screen.small} {
        margin-top: 30px;
      }

      .video-item {
        width: 100%;
        height: 200px;
        overflow: hidden;
        position: relative;
        margin: 8px 0;
        width: 100%;
        @media ${screen.small} {
          width: 49%;
          height: 230px;
        }
        @media ${screen.medium} {
          width: 32.5%;
          height: 240px;
        }
        @media ${screen.large} {
          height: 270px;
        }
        @media ${screen.xLarge} {
          margin: 10px 0;
          height: 335px;
        }

        &__texts {
          cursor: pointer;
          position: absolute;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;
          transition: ${transHover};
          padding-top: 20px;
          padding-left: 20px;
          z-index: 2;
          @media ${screen.withCursor} {
            &:hover {
              background: rgba(200, 29, 41, 0.9);
            }
          }

          .video-title {
            color: ${mainWhite};
            font-family: ${gotham};
            font-size: 1.2rem;
            position: relative;
            max-width: 440px;
            padding-right: 20px;
            @media ${screen.small} {
              font-size: 1.3rem;
            }
            @media ${screen.xLarge} {
              padding-right: 0;
            }

            &::after {
              content: "";
              display: block;
              width: 45px;
              height: 3px;
              margin: 18px 0;
              background: ${mainWhite};
            }
          }

          .video-subtitle {
            color: ${mainWhite};
            font-family: ${gotham};
            font-size: 0.92rem;
            display: inline-block;
            margin-right: 5px;
            @media ${screen.small} {
              font-size: 1rem;
            }

            &:last-child {
              .video-subtitle--comma {
                display: none;
              }
            }
          }
        }

        &__video {
          width: 100% !important;
          height: 100% !important;
          pointer-events: none;
          position: relative !important;

          img {
            position: absolute !important;
            z-index: 1;
          }
        }
        .react-player__play-icon,
        .react-player__shadow {
          display: none !important;
        }
      }

      &::after {
        content: "";
        width: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
        @media ${screen.small} {
          width: 49%;
        }
        @media ${screen.medium} {
          width: 32.5%;
        }
      }
    }

    &__projects-link {
      background: ${navyBlue};
      transition: ${transHover};
      display: table;
      padding: 16px 29px;
      color: ${mainWhite};
      font-family: ${gotham};
      font-size: 1.1rem;
      &:hover {
        background: ${mainRed};
      }
    }

    &__paginate {
      display: flex;
      margin-top: 20px;
      flex-wrap: wrap;
      max-width: 1000px;

      .box {
        display: block;
        cursor: pointer;
        width: 38px;
        height: 40px;
        border: 1px ${navyBlue} solid;
        margin-right: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: ${transHover};

        &:hover {
          border: 1px ${mainRed} solid;
        }

        &:hover .body {
          fill: ${mainRed};
          transition: ${transHover};
        }

        &:hover .page-num {
          color: ${mainRed};
          transition: ${transHover};
        }

        .page-num {
          color: ${navyBlue};
          font-family: ${gotham};
          font-size: 1.1rem;
        }

        &--active {
          background: ${mainRed};
          border: 1px ${mainRed} solid;
          pointer-events: none;
          &:hover {
            background: ${mainWhite};
            border: 1px ${navyBlue} solid;
          }
          &:hover .page-num {
            color: ${navyBlue};
          }
          .page-num {
            color: ${mainWhite};
          }
        }
      }
    }
  }
`;

const ServicePage = ({ data }) => {
  const [isTablet, setTablet] = useState(
    typeof window !== "undefined" && window.innerWidth < 1160
  );
  const [num, setNum] = useState(
    typeof window !== "undefined" && window.innerWidth < 1160 ? 4 : 9
  );
  // basic hack to trigger the video modal component to watch location hash changes
  const [triggerLightboxComponent, setTriggerLightboxComponent] = useState(
    false
  );
  const [paginateFrom, setPaginateFrom] = useState(0);
  const [paginateTo, setPaginateTo] = useState(num);
  const [currentPage, setCurrentPage] = useState(1);
  const [scrollUpTo, setScrollUpTo] = useState(0);
  const projectList = data.content.data.projects;
  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : `Service`;
  const metaDescription = data.content.data.meta_description
    ? data.content.data.meta_description
    : `Service`;
  const serviceTitle = data.content.data.title
    ? data.content.data.title.text
    : `Service`;

  const projectListElement = useRef(null);

  const featuredWorkList = isTablet
    ? data.content.data.projects.slice(0, 2)
    : data.content.data.projects;

  const featuredBlogList = data.content.data.blogs;

  const backgroundColour =
    data.bgColour.data.background_colour.length === 4
      ? data.bgColour.data.background_colour
      : [
          { colour: navyBlue },
          { colour: navyBlue },
          { colour: navyBlue },
          { colour: navyBlue },
        ];

  useEffect(() => {
    const CheckBrowserWidth = () => {
      if (window.innerWidth > 1160) {
        setTablet(false);
      } else {
        setTablet(true);
      }
    };
    window.addEventListener("resize", CheckBrowserWidth);

    return () => {
      window.removeEventListener("resize", CheckBrowserWidth);
    };
  }, [isTablet]);

  useEffect(() => {
    setScrollUpTo(projectListElement.current.offsetTop);
  }, []);

  const projectsPage = new Array(Math.ceil(projectList.length / num)).fill(" ");

  const renderProjects = projectList.slice(paginateFrom, paginateTo);

  const primaryContent = data.content.data.body.map((content) => (
    <div className="primary-content__row" key={content.id}>
      <div
        className="texts-column"
        dangerouslySetInnerHTML={{ __html: content.primary.description.html }}
      />
      <div className="img-column">
        {content.items.map(
          (item, i) =>
            item.image.gatsbyImageData && (
              <div className="img-column__img" key={i}>
                <GatsbyImage
                  image={item.image.gatsbyImageData}
                  alt={item.image.alt || "Featured"}
                />
              </div>
            )
        )}
      </div>
    </div>
  ));

  const viewVideo = (videoId) => {
    window.history.replaceState(null, null, `#${videoId}`);
    setTriggerLightboxComponent(!triggerLightboxComponent);
    // window.location.hash = videoId;
  };

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} image={logo} />
      <Wrapper bgColour={backgroundColour}>
        <h1 className="heading">
          <Arrow />
          {serviceTitle}
        </h1>
        <div className="primary-content">{primaryContent}</div>

        <section className="featured-project">
          {featuredWorkList.length + featuredBlogList.length > 0 && (
            <h2 className="featured-project__heading">
              <Spy
                to="/"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-150}
              >
                <Arrow />
              </Spy>
              Our Work
            </h2>
          )}

          <ul className="featured-project__video-list" ref={projectListElement}>
            {renderProjects.map(
              (item, i) =>
                item.project.document && (
                  <li
                    className="video-item"
                    onClick={() =>
                      viewVideo(
                        getVimeoId(item.project.document.data.video_url)
                      )
                    }
                    key={i}
                  >
                    <div className="video-item__texts">
                      <h2 className="video-title">
                        {item.project.document.data.title.text}
                      </h2>
                      {item.project.document.data.categories.map((cat, i) => (
                        <p className="video-subtitle" key={i}>
                          {cat.category.document &&
                            cat.category.document.data.title.text}
                          <span className="video-subtitle--comma">,</span>
                        </p>
                      ))}
                    </div>
                    <ReactPlayer
                      className="video-item__video"
                      url={item.project.document.data.video_url}
                      light={`https://vumbnail.com/${getVimeoId(
                        item.project.document.data.video_url
                      )}.jpg`}
                    />
                  </li>
                )
            )}
            {featuredBlogList.map(
              (item, i) =>
                item.blog.document && (
                  <li
                    className="video-item"
                    onClick={() =>
                      navigate(
                        `/news/${toSlug(item.blog.document.data.title.text)}`
                      )
                    }
                    key={i}
                  >
                    <div className="video-item__texts">
                      <h2 className="video-title">
                        {item.blog.document.data.title.text}
                      </h2>
                      {item.blog.document.data.categories.map((cat, i) => (
                        <p className="video-subtitle" key={i}>
                          {cat.category.document &&
                            cat.category.document.data.title.text}
                          <span className="video-subtitle--comma">,</span>
                        </p>
                      ))}
                    </div>
                    <div className="video-item__video">
                      {item.blog.document.data.blog_featured_image
                        .thumbnails && (
                        <GatsbyImage
                          image={
                            item.blog.document.data.blog_featured_image
                              .thumbnails.thumbnail.gatsbyImageData
                          }
                          alt="Featured"
                        />
                      )}
                    </div>
                  </li>
                )
            )}
          </ul>
          {projectsPage.length > 1 && (
            <div className="featured-project__paginate">
              {projectsPage.map((empty, i) => (
                <span
                  className={currentPage === i + 1 ? `box box--active` : `box`}
                  key={i + 1}
                  onClick={() => {
                    setPaginateFrom(num * i);
                    setPaginateTo(num * (i + 1));
                    setCurrentPage(i + 1);
                    window.scrollTo(0, scrollUpTo - 120);
                  }}
                >
                  <span className="page-num">{i + 1}</span>
                </span>
              ))}
            </div>
          )}
        </section>
        <VideoModal triggerLightboxComponent={triggerLightboxComponent} />
      </Wrapper>
    </Layout>
  );
};

export default ServicePage;

export const data = graphql`
  query($id: String!) {
    content: prismicService(id: { eq: $id }) {
      data {
        title_tag
        meta_description
        title {
          text
        }
        projects {
          project {
            document {
              ... on PrismicProject {
                data {
                  title {
                    text
                  }
                  categories {
                    category {
                      document {
                        ... on PrismicCategory {
                          data {
                            title {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                  date
                  video_url
                  credits {
                    description
                  }
                }
              }
            }
          }
        }
        blogs {
          blog {
            document {
              ... on PrismicBlog {
                data {
                  title {
                    text
                  }
                  categories {
                    category {
                      document {
                        ... on PrismicCategory {
                          data {
                            title {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                  blog_featured_image {
                    alt
                    thumbnails {
                      thumbnail {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicServiceDataBodyContent {
            id
            primary {
              description {
                html
              }
            }
            items {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    bgColour: prismicHomePage {
      data {
        background_colour {
          colour
        }
      }
    }
  }
`;
